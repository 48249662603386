/* global $ */
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import 'owl.carousel/dist/owl.carousel.min.js';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.css';
class App {
    bootstrap;
    
    constructor() {
        this.disableLogger();
        this.getBootstrap();
        this.initTooltips($('[data-bs-toggle="tooltip"]'));
        this.initToasts($('.toast'));
        this.initForms($('form'));
        this.initFontAwesome();
        this.initStickyMenu();
        this.initMatrixContent();
        this.initAccordion();
        this.initTabs();
        $('body').css('opacity', 1);

        Fancybox.bind('[data-fancybox="gallery"]', {
            Toolbar: {
                display: {
                    left: ["infobar"],
                    middle: [],
                    right: ["close"],
                },
            },
        });
        Fancybox.bind('[data-fancybox="ani-gallery"]', {
            Toolbar: {
                display: {
                    left: ["infobar"],
                    middle: [],
                    right: ["close"],
                },
            },
        });
        Fancybox.bind('[data-fancybox="zoom-image"]', {
            Toolbar: {
                display: {
                    left: ["infobar"],
                    middle: [],
                    right: ["close"],
                },
            },
        });
        console.log('App initialized');
    }

    getBootstrap() {
        if (!this.bootstrap) {
            this.bootstrap = import(/* webpackChunkName: "bootstrap" */ 'bootstrap');
        }
        return this.bootstrap;
    }

    disableLogger() {
        if (window.Globals.env == 'production') {
            console.log = function () { };
        }
    }

    initFontAwesome() {
        if ($('.fa-brands, .fa-solid, .fa-regular, .fa-light, .fa-thin, .fa-duotone').length) {
            import(/* webpackChunkName: "fontawesome" */ './components/fontawesome');
        }
    }

    initAccordion() {
        if ($('.accordion').length) {
            import(/* webpackChunkName: "accordion" */ './components/accordion');
        }
    }

    initTabs() {
        if ($('.tabs').length) {
            import(/* webpackChunkName: "tabs" */ './components/tabs');
        }
    }

    initForms($forms) {
        if ($forms.length) {
            import(/* webpackChunkName: "form" */ './components/form').then(chunk => {
                $.each($forms, (i, elem) => {
                    new chunk.Form($(elem));
                });
            });
        }
    }

    initToasts($elems) {
        if ($elems.length) {
            this.getBootstrap().then((bootstrap) => {
                $.each($elems, function (i, elem) {
                    new bootstrap.Toast(elem);
                });
            });
        }
    }

    initTooltips($elems) {
        if ($elems.length) {
            this.getBootstrap().then((bootstrap) => {
                $.each($elems, function (i, elem) {
                    new bootstrap.Tooltip(elem);
                });
            });
        }
    }

    initStickyMenu() {
        var stickyNav = function () {
            var scrollTop = $(window).scrollTop() + 30;
            if (scrollTop > 150) {
                $('.sticky').addClass('fixed');
            } else {
                $('.sticky').removeClass('fixed');
            }
        };
        stickyNav();
        var _this = this;
        var ofv = [];
        $(window).scroll(function () {
            if ($('.object-feed-list').length) {
                var maxlength = $('#object_feed').attr('max-length');
                var offset = $('#object_feed').attr('current-length');
                if (offset < parseInt(maxlength)) {
                    
                    let newvalue = parseInt(offset) + 4;
                    if ($.inArray(newvalue, ofv) === -1) {
                        ofv.push(newvalue);
                        $('#object_feed').attr('current-length', newvalue);
                        _this.loadMore(newvalue);
                    }
                    
                }
            }
            stickyNav();
        });
    }

    loadMore(newvalue) {
        var offset = $('#object_feed').attr('current-length');
        var eid = $('#object_feed').attr('filterentry-id');
        var limit = 4;
        //console.log(ofv[ofv.length - 1]);

        $.ajax({
            url: '/ajax/getObjects',
            method: 'get',
            data: {
                offset: newvalue - 4,
                limit: limit,
                eid: eid
            }
        }).done((data) => {
            let newvalue = parseInt(offset) + parseInt(limit);
            $('#object_feed').attr('current-length', newvalue);
            let objects = $(data);
            $('#object_feed').append(objects);
        });
    }

    initMatrixContent() {
        if ($('.matrix-block').length) {
            import(/* webpackChunkName: "matrixContent" */ './components/matrix');
        }
        if ($('.notification-bar').length) {
            import(/* webpackChunkName: "slick" */ './components/slick').then(() => {
                $('.notification-slider').slick({
                    slidesToScroll: 1,
                    autoplay: true,
                    speed: 1000,
                    autoplaySpeed: 4000,
                    arrows: false,
                    dots: false,
                    vertical: true,
                    verticalSwiping: true
                });
            });
        }
        if ($('.sponsor-slider').length) {
            import(/* webpackChunkName: "slick" */ './components/slick').then(() => {
                $('.sponsor-slider').slick({
                    autoplay: true,
                    speed: 1000,
                    autoplaySpeed: 4000,
                    arrows: false,
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    pauseOnHover: false,
                    responsive: [
                        {
                            breakpoint: 1199,
                            settings: {
                                slidesToShow: 5,
                                slidesToScroll: 1,
                            }
                        },
                        {
                            breakpoint: 991,
                            settings: {
                                slidesToShow: 4,
                                slidesToScroll: 1,
                            }
                        },
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 1,
                            }
                        },
                        {
                            breakpoint: 600,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 1,
                            }
                        }
                    ]
                });
            });
        }
        import(/* webpackChunkName: "jquery-match-height" */ './components/jquery-match-height.js').then(() => {
            $(function () {
                $('.news-card').matchHeight({
                    byRow: true,
                    property: 'height',
                    target: null,
                    remove: false
                });
                $('.event-card .card-body').matchHeight({
                    byRow: true,
                    property: 'height',
                    target: null,
                    remove: false
                });
            });
        });
    }

    addAlert(message, $elem, type = "danger", dismissible = false) {
        let _class = "alert alert-" + type;
        if (dismissible) {
            _class += ' alert-dismissible fade show';
            message += '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>';
        }
        $('<div class="' + _class + '">' + message + '</div>').appendTo($elem);
    }

    addAlerts(messages, $elem, type = "danger", dismissible = false) {
        for (let i in messages) {
            this.addAlert(messages[i], $elem, type, dismissible);
        }
    }
}

export default App;